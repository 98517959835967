import React, { useRef, useState } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Grid,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const members = [
  {
    policyNumber: '2215',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '22154',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '22151',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
  {
    policyNumber: '21315',
    startDate: '15.10.2024',
    endDate: '22.10.2024',
    insuranceDays: 7,
  },
];

const useStyles = makeStyles((theme) => ({
  invoiceContainer: {
    padding: 20,
    border: '1px solid #ccc',
  },
  invoiceRecipient: {
    fontSize: 18,
    fontWeight: 500,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const CarrierReport = () => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [data, setData] = useState<any>();
  const [totalAmount, setTotalAmount] = useState(0);
  const [carriers, setCarriers] = useState<any>([
    { id: 7, name: 'libra', displayName: 'Libra' },
  ]);
  const [currentCarrier, setCurrentCarrier] = useState<any>(carriers[0]);
  const invoiceRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const handleFetchData = () => {
    setData({ members });
    setTotalAmount(
      members.reduce(
        (acc: number, item: any) => (acc += Number(item.insuranceDays) * 0.6),
        0
      )
    );
  };

  const handleExportPDF = async () => {
    const input = invoiceRef.current;
    if (!input) {
      return;
    }
    const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size in portrait mode
    const pageHeight = pdf.internal.pageSize.height * 5.3;
    let y = 0; // Height position where new content should be added

    console.log('input.scrollHeight', input.scrollHeight);
    console.log('window.scrollY', window.scrollY);
    console.log('pageHeight', pageHeight);
    while (y < input.scrollHeight) {
      // Render each page using html2canvas
      const canvas = await html2canvas(input, {
        scrollY: -window.scrollY,
        y: y,
        height: pageHeight,
        windowHeight: pageHeight,
        scale: 2, // Increase scale for better resolution
      });

      const imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);

      y += pageHeight; // Move to the next page height

      if (y < input.scrollHeight) {
        console.log('adding new page');
        pdf.addPage();
      }
    }

    pdf.save('invoice.pdf');
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Container>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={5}>
            <TextField
              id="startDate"
              fullWidth
              margin="normal"
              variant="outlined"
              label="Start Date"
              type="date"
              onChange={(e) => {
                if (e.target.value !== '') {
                  setStartDate(new Date(e.target.value));
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              id="endDate"
              fullWidth
              margin="normal"
              variant="outlined"
              label="End Date"
              type="date"
              onChange={(e) => {
                if (e.target.value) {
                  setEndDate(new Date(e.target.value));
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl
              variant="outlined"
              style={{ marginTop: 16, marginBottom: 8 }}
              required
              fullWidth
            >
              <InputLabel id="carrier-label">Carrier</InputLabel>
              <Select
                labelId="carrier-label"
                id="carrier"
                value={currentCarrier || 'Bla Bla'}
                onChange={(e: any) => {
                  console.log(e.target.value);
                  setCurrentCarrier(e.target.value);
                }}
                label="Carrier"
              >
                {carriers.map((carrier: any) => (
                  <MenuItem key={carrier.name} value={carrier.displayName}>
                    {carrier.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleFetchData}
              fullWidth
            >
              Go
            </Button>
          </Grid>
        </Grid>

        {data?.members && (
          <>
            <div ref={invoiceRef} className={classes.invoiceContainer}>
              <p className={classes.invoiceRecipient}>
                To: Libra Insurance Company. Ltd. (registration no. 515761625)
              </p>
              <p className={classes.invoiceRecipient}>Address: Harokmim St 26, Holon, 5885849</p>
              <p className={classes.invoiceRecipient}>Phone: 073-394-9222</p>
              <p className={classes.invoiceRecipient}>
                From: FairFare, Ltd. (formerly Teramedics, Ltd.) (registration
                no. 516997871)
              </p>
              <h2>RE: PAYMENT REQUEST</h2>
              <Table style={{ marginTop: 20 }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Policy Number</StyledTableCell>
                    <StyledTableCell>Start Date</StyledTableCell>
                    <StyledTableCell>End Date</StyledTableCell>
                    <StyledTableCell>No. of insurance days</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.members.map((member: any, index: any) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{member.policyNumber}</StyledTableCell>
                      <StyledTableCell>{member.startDate}</StyledTableCell>
                      <StyledTableCell>{member.endDate}</StyledTableCell>
                      <StyledTableCell>{member.insuranceDays}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <TableRow>
                    <TableCell style={{ border: 'none' }}></TableCell>
                    <TableCell style={{ border: 'none' }}></TableCell>
                    <TableCell style={{ borderTop: '2px solid black' }}>
                      Fee per day
                    </TableCell>
                    <TableCell style={{ borderTop: '2px solid black' }}>
                      $0.6
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}></TableCell>
                    <TableCell style={{ border: 'none' }}></TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>{totalAmount.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}></TableCell>
                    <TableCell style={{ border: 'none' }}></TableCell>
                    <TableCell>VAT</TableCell>
                    <TableCell>17%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}></TableCell>
                    <TableCell style={{ border: 'none' }}></TableCell>
                    <TableCell>VAT Amount</TableCell>
                    <TableCell>{(totalAmount * 0.17).toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ border: 'none' }}></TableCell>
                    <TableCell style={{ border: 'none' }}></TableCell>
                    <TableCell>Grand Total</TableCell>
                    <TableCell>
                      {(totalAmount + totalAmount * 0.17).toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleExportPDF}
              style={{ marginTop: 20 }}
            >
              Export to PDF
            </Button>
          </>
        )}
      </Container>
    </MuiPickersUtilsProvider>
  );
};
